<!--
 * @Author: zhangjingqing
 * @Date: 2022-04-23 22:57:26
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-05-05 20:06:37
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\tenantManagement\fields\FieldModal.vue
-->
<template>
  <a-modal
    :title="title"
    :width="800"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleSave"
    @cancel="handleCancel"
  >
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
      <a-form-item label="字段名称">
        <a-input
          v-decorator="[
            'expandFieldName',
            { rules: [{ required: true, message: '字段名称不能为空' }] },
          ]"
          placeholder="请输入字段名称"
        />
      </a-form-item>
      <a-form-item label="字段长度">
        <a-input
          v-decorator="[
            'expandFieldLength',
            { rules: [{ required: true, message: '字段长度不能为空' }] },
          ]"
          placeholder="请输入字段长度"
        />
      </a-form-item>
      <a-form-item label="字段类型">
        <a-select
          v-decorator="[
            'expandFieldType',
            { rules: [{ required: true, message: '字段类型不能为空!' }] },
          ]"
          placeholder="请选择字段类型"
        >
          <a-select-option :value="1"> 整型 </a-select-option>
          <a-select-option :value="2"> 字符串 </a-select-option>
          <a-select-option :value="3"> 布尔 </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import pick from "lodash.pick";
import { createExtendField, updateExtendField } from "@/api/tenant";
export default {
  name: "tenantModal",
  data() {
    return {
      status: 1,
      visible: false,
      record: {},
      form: this.$form.createForm(this, { name: "form" }),
      confirmLoading: false,
    };
  },
  methods: {
    async show(status, record) {
      this.visible = true;
      this.status = status;
      if (record.id) {
        this.record = record;
        this.$nextTick(() => {
          this.form.setFieldsValue(
            pick(
              record,
              "expandFieldName",
              "expandFieldLength",
              "expandFieldType"
            )
          );
        });
      }
    },
    handleCancel() {
      this.form.resetFields();
      this.visible = false;
    },
    handleSave() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let res;
          if (this.status == 2) {
            res = await createExtendField(Object.assign(values));
          } else {
            res = await updateExtendField(Object.assign(this.record, values));
          }
          if (res.status == 200) {
            this.$message.success(
              this.status == 2 ? "新增成功！" : "修改成功！"
            );
            this.handleCancel();
            this.$emit("reload");
          } else {
            this.$notification.error({
              message: "系统提示",
              description: res.msg || res.message,
              duration: 4,
            });
          }
        }
      });
    },
  },
  computed: {
    title() {
      let title;
      switch (this.status) {
        case 1:
          title = "查看";
          break;
        case 2:
          title = "新增字段";
          break;
        case 3:
          title = "编辑字段";
          break;
      }
      return title;
    },
  },
};
</script>
<style lang="less" scoped></style>
