var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":_vm.title,"width":800,"visible":_vm.visible,"confirm-loading":_vm.confirmLoading},on:{"ok":_vm.handleSave,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 16 }}},[_c('a-form-item',{attrs:{"label":"字段名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'expandFieldName',
          { rules: [{ required: true, message: '字段名称不能为空' }] },
        ]),expression:"[\n          'expandFieldName',\n          { rules: [{ required: true, message: '字段名称不能为空' }] },\n        ]"}],attrs:{"placeholder":"请输入字段名称"}})],1),_c('a-form-item',{attrs:{"label":"字段长度"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'expandFieldLength',
          { rules: [{ required: true, message: '字段长度不能为空' }] },
        ]),expression:"[\n          'expandFieldLength',\n          { rules: [{ required: true, message: '字段长度不能为空' }] },\n        ]"}],attrs:{"placeholder":"请输入字段长度"}})],1),_c('a-form-item',{attrs:{"label":"字段类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'expandFieldType',
          { rules: [{ required: true, message: '字段类型不能为空!' }] },
        ]),expression:"[\n          'expandFieldType',\n          { rules: [{ required: true, message: '字段类型不能为空!' }] },\n        ]"}],attrs:{"placeholder":"请选择字段类型"}},[_c('a-select-option',{attrs:{"value":1}},[_vm._v(" 整型 ")]),_c('a-select-option',{attrs:{"value":2}},[_vm._v(" 字符串 ")]),_c('a-select-option',{attrs:{"value":3}},[_vm._v(" 布尔 ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }