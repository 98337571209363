<!--
 * @Author: zhangjingqing
 * @Date: 2022-04-23 22:40:41
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-05-27 17:51:30
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\tenantManagement\fields\Index.vue
-->
<template>
  <div class="container">
    <div class="options">
      <a-button type="primary" icon="plus" @click="handleAdd"
        >新增字段</a-button
      >
    </div>
    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="{ current: current, pageSize: pageSize, total: total }"
      @change="handleTableChange"
    >
      <span slot="action" slot-scope="text, record">
        <a @click="handleEdit(record)">编辑</a>
        <a-divider type="vertical" />
        <a @click="handleDelete(record.id)">删除</a>
      </span>
    </a-table>
    <field-modal ref="fieldModal" @reload="queryList"></field-modal>
  </div>
</template>
<script>
import FieldModal from "./FieldModal";
import { queryExtendFieldServiceList, deleteExtendField } from "@/api/tenant";
const columns = [
  {
    title: "字段名称",
    dataIndex: "expandFieldName",
    key: "expandFieldName",
  },
  {
    title: "字段长度",
    dataIndex: "expandFieldLength",
    key: "expandFieldLength",
  },
  {
    title: "字段类型",
    key: "expandFieldType",
    dataIndex: "expandFieldType",
    align: "center",
    customRender: function (text) {
      if (text == 1) {
        return "整型";
      } else if (text == 2) {
        return "字符串";
      } else {
        return "布尔";
      }
    },
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  name: "trigger",
  components: { FieldModal },
  data() {
    return {
      loading: false,
      data: [],
      columns,
      current: 1,
      pageSize: 10,
      total: 0,
    };
  },
  created() {
    this.queryList();
  },
  methods: {
    async queryList() {
      this.loading = true;
      let res = await queryExtendFieldServiceList({
        page: this.current,
        pageSize: this.pageSize,
      });
      if (res.status == 200) {
        this.data = res.data.rows;
        this.total = res.data.records;
      } else {
        let msg = res.msg || res.message;
        let len = msg.length;
        this.$notification.error({
          message: "系统提示",
          description: len > 100 ? "系统错误，请联系管理员" : msg,
          duration: 4,
        });
      }
      this.loading = false;
    },
    handleDelete(id) {
      const that = this;
      this.$confirm({
        title: "确认删除",
        content: "是否删除当前数据?",
        onOk: async function () {
          let res = await deleteExtendField({ id: id });
          if (res.status == 200) {
            that.$message.success("删除成功");
            that.queryList();
          } else {
            that.$notification.error({
              message: "系统提示",
              description: res.msg || res.message,
              duration: 4,
            });
          }
        },
      });
    },
    handleAdd() {
      this.$refs.fieldModal.show(2, {});
    },
    handleEdit(record) {
      this.$refs.fieldModal.show(3, record);
    },
    handleTableChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.total = pagination.total;
      this.queryList();
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  padding: 20px;
  background: #fff;
  min-height: 280px;
}
.options {
  padding-bottom: 16px;
}
</style>
